import React, {Component} from "react";

import Payment from "../../Components/ProfileComponents/Payment";

import AbonementCardContainer from "../AbonementCardContainer";
import {AbonementContainer as Container} from "../../Components/AbonementCard";
import IsLoading from "../ProfileContainer/Abonements/Loading";

import AbonementStore from "../../Stores/AbonementStore";
import UIStore from "../../Stores/UIStore";

import {observable} from "mobx";
import {observer} from "mobx-react";
import userStore from "../../Stores/UserStore";
import SettingsStore from "../../Stores/SettingsStore";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";

@observer
class AbonementsContainer extends Component {
    @observable
    subscriptionCode = "";

    @observable
    cost = "";

    @observable
    subscriptionPermit = "";

    @observable
    subscriptionPermitType = "";

    @observable
    subscriptionPermitStartDate = "";

    @observable
    subscriptionPermitTs = "";


    componentDidMount() {
        AbonementStore.loadSubscriptionsIfNeeded();
    }

    UNSAFE_componentWillReceiveProps() {
        AbonementStore.loadSubscriptionsIfNeeded();
    }

    setPaymentData(data) {
        this.cost = data.cost;
        this.subscriptionCode = data.parkingPermitCode;
        this.subscriptionPermit = data.parkingPermit;
        this.subscriptionPermitType = data.parkingPermitType;
        this.subscriptionPermitStartDate = data.parkingPermitStartDate;
        this.subscriptionPermitTs = data.parkingPermitTs;
    }

    resetPaymentData() {
        this.cost = "";
        this.subscriptionCode = "";
        this.subscriptionPermit = "";
        this.subscriptionPermitType = "";
        this.subscriptionPermitTs = "";
        this.subscriptionPermitStartDate = "";
    }

    getPaymentData() {
        return {
            codeSubscription: this.subscriptionCode,
            typeSubscription: this.subscriptionPermitType,
            startDate: this.subscriptionPermitStartDate,
            selectedTs: this.subscriptionPermitTs,
            cost: this.cost
        };
    }

    handleAbonementCardClick = paymentData => {
        this.setPaymentData(paymentData);
    };

    handleModalExit() {
        this.resetPaymentData();
        UIStore.closeSubscriptionModal();
    }

    handleBuySubscription() {
        UIStore.openSubscriptionModal();
    }

    renderCards() {
        const {t} = this.props;
        const abonements = AbonementStore.abonements;
        if (abonements.length > 0)
            return AbonementStore.abonements.map((element, key) => (
                /* todo: проперти zones={element.zone} больше не испольтзуется, необходимо его выпилить */
                <AbonementCardContainer
                    parkingPermit={element.parkingPermit}
                    parkingPermitCode={element.parkingPermitCode}
                    parkingPermitType={element.parkingPermitType}
                    parkingPermitDesc={element.parkingPermitDesc}
                    parkingPermitStartDate={element.parkingPermitStartDate}
                    parkingPermitTs={element.parkingPermitTs}
                    cost={element.cost}
                    zones={element.zone}
                    onClick={this.handleAbonementCardClick}
                    key={key}
                />
            ));

        return <span>{t("abonements:errors:subscriptionsIsEmpty")}</span>;
    }

    render() {
        return (
            <>
                {SettingsStore.settings.useSubscriptionConstructor
                    ? <>
                        <Link to={'/profile/constructor'}>
                            <Button  color="primary" >
                                Купить абонемент
                            </Button>
                        </Link>
                    </>
                    : <>
                        {!userStore.profile.isLoading ? (
                            <Payment
                                isOpen={UIStore.modals.subscriptionModal}
                                onSubmit={UIStore.closeSubscriptionModal}
                                onExit={UIStore.closeSubscriptionModal}
                                data={{...this.getPaymentData()}}
                                actionType="subscription"
                            />
                        ) : null}
                        <IsLoading isLoading={AbonementStore.status.isLoading}>
                            <Container empty={AbonementStore.abonements.length === 0}>
                                {this.renderCards()}
                            </Container>
                        </IsLoading>
                    </>
                }
            </>);
    }
}

export default AbonementsContainer;
