import React, { Component } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { translate } from 'react-i18next';

import AbonementStore from '../../Stores/AbonementStore';
import UIStore from '../../Stores/UIStore';

import AbonementCard from '../../Components/AbonementCard';

@translate(['abonements', 'dates'], { wait: true })
@observer
class AbonementCardContainer extends Component {
  static defaultProps = {
    onClick: () => null
  };

  handlePaymentClick = paymentData => {
    UIStore.openSubscriptionModal();

    this.props.onClick({...paymentData, parkingPermitType: this.props.parkingPermitType});
  };

  getTitle() {
    const { t, parkingPermitType } = this.props;

    switch (parkingPermitType) {
      case AbonementStore.TYPES.DAY:
        return `${t('abonements:abonementTo')} 1 ${t('dates:workingDay')}`;
      case AbonementStore.TYPES.WEEK:
        return `${t('abonements:abonementFor')} 1 ${t('dates:week')}`;
      case AbonementStore.TYPES.MOUNTH:
        return `${t('abonements:abonementFor')} 1 ${t('dates:mounth')}`;
      case AbonementStore.TYPES.QUARTER:
        return `${t('abonements:abonementFor')} 1 ${t('dates:quarter')}`;
      case AbonementStore.TYPES.YEAR:
        return `${t('abonements:abonementFor')} 1 ${t('dates:year')}`;
      default:
        return '';
    }
  }

  getZones() {
    const { zones, t } = this.props;
    const jsZones = toJS(zones);

    if (Array.isArray(jsZones)) return zones.join(', ');

    return t('operationsHistory:operationType:all');
  }

  render() {
    const { parkingPermit, parkingPermitCode, parkingPermitDesc, parkingPermitStartDate, parkingPermitTs, cost } = this.props;

    return (
      <AbonementCard
        title={parkingPermit}
        parkingPermit={parkingPermit}
        parkingPermitCode={parkingPermitCode}
        parkingPermitDesc={parkingPermitDesc}
        parkingPermitStartDate={parkingPermitStartDate}
        parkingPermitTs={parkingPermitTs}
        zones={this.getZones()}
        cost={cost}
        onClick={this.handlePaymentClick}
      />
    );
  }
}

export default AbonementCardContainer;
