import React from 'react';
import SubscriptionFormView from '../View';
import { observer } from 'mobx-react';
import { translate } from 'react-i18next';
import moment from 'moment';

import userStore from '../../../../../Stores/UserStore';
import SettingsStore from '../../../../../Stores/SettingsStore';
import PaymentStore from '../../../../../Stores/PaymentStore';

import { ABONEMENT_START } from '../../../../../Config';
import AbonementStore from '../../../../../Stores/AbonementStore';
import ModalStore from "../../../../../Stores/ModalStore";
import {withRouter} from "react-router-dom";

const initialState = {
  toPaymentService: null,
  gettingPrice: false,
};

@translate(['parking'], { wait: true })
@observer
class SubscriptionFormContainer extends React.Component {
  constructor() {
    super();
    this.state = initialState;
  }

  reset = () => {
    this.setState({ ...initialState });
  };

  handleFormChangeWhenValid = async (values) => {
    const { codeSubscription, typeSubscription } = this.props;
    const prevDay = typeSubscription === AbonementStore.TYPES.PREV_DAY;

    this.setState({
      gettingPrice: true,
    });
    if (values.service && 'Квитанция' == values.service.methodName) {
      this.props.setReceiptMode({
        isReceiptMode: true,
        receiptReferer: 'subscription',
        grz: values.ts.numberTs,
        codeSubscription: codeSubscription,
      });
    } else {
      const response = await PaymentStore.paySubscription({
        vehicle: values.ts,
        timestart: prevDay ? null : values.date.format(),
        paymentService: values.service,
        phone: values.phone,
        codeSubscription
      });

      if (response) {
        if (response.status && response.status === 'ok') {
          if (response['СуммаПополнения'] === 0) {
            ModalStore.openModal('notify', {
              message: 'Абонемент успешно приобретен.'
            })
              this.props.history.push('/profile/abonements');
          }
        }
        this.setState({
          toPaymentService: response.hasOwnProperty("RedirectURL")
            ? response.RedirectURL.replace(",", ".")
            : null
        });
      }

      this.setState({
        gettingPrice: false
      });

      return response;
    }
  };

  onAbort = () => {
    const { onAbort } = this.props;

    onAbort();
  };

  handleSubmit = async (values) => {
    const { price } = this.props;
    const { onSubmit } = this.props;

    if (userStore.isMoneyEnough(price)) {
      const response = await this.handleFormChangeWhenValid(values);

      if (response.status === 'ok') {
        await onSubmit(values);
        await Promise.all([
          userStore.updateBenefits(),
          userStore.updateProfile(),
        ]);
      }

      return response;
    }
  };

  render() {
    const { price, typeSubscription, startDate, disableSelectTs, disableSelectDate } = this.props;
    const amountToPay = price - userStore.getBalance();
    const prevDay = typeSubscription === AbonementStore.TYPES.PREV_DAY;
    const dateStart = startDate ? moment(startDate) : (prevDay ? moment().add(-1, 'day') : moment().add(ABONEMENT_START, 'day'));
    const minDate = prevDay ? moment().add(-1, 'day') : moment().add(ABONEMENT_START, 'day');
    const maxDate = prevDay ? moment().add(-1, 'day') : moment().add(1, 'month');

    return (
      <SubscriptionFormView
        t={this.props.t}
        toPaymentService={this.state.toPaymentService}
        ts={userStore.getTs()}
        selectedTs={this.props.selectedTs}
        dateStart={dateStart}
        minDate={minDate}
        maxDate={maxDate}
        disableSelectDate={disableSelectDate}
        disableSelectTs={disableSelectTs}
        price={price}
        typeSubscription={typeSubscription}
        moneyEnough={userStore.isMoneyEnough(price)}
        amountToPay={amountToPay}
        gettingPrice={this.state.gettingPrice}
        dontUseBalance={SettingsStore.settings.dontUseBalance}
        services={SettingsStore.settings.paymentsMethods}
        phones={userStore.getAllPhones()}
        onGetPrice={this.handleFormChangeWhenValid}
        onChange={this.reset}
        onSubmit={this.handleSubmit}
        onAbort={this.onAbort}
      />
    );
  }
}

export default withRouter(SubscriptionFormContainer);
